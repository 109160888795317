// 用于存放一些通用的方法工具

import { statusList } from "@api/enterprise";
import { userInfoApi, addBiaozhun } from "@api/project";
import moment from "moment";

// 时间格式化
export function formattedDate(date) {
  return date.toLocaleDateString("zh-CN", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
  });
}

export function fetchStatusList(number, page, token) {
  return new Promise((resolve, reject) => {
    let param = {
      number: number,
      page: page,
      limit: 100,
      token: token, // 获取token
    };

    statusList(param)
      .then((res) => {
        const responseData = res.data.data[0];
        resolve(responseData); // 将解析后的数据传递给调用端
      })
      .catch((err) => {
        reject(err); // 将错误传递给调用端
      });
  });
}

// 项目立项方法（TODO 无法发挥作用）
export function EditStandard(number, status, token) {
  // this.form.number = this.number;
  // this.form.status = '1.40';
  // this.form.token = this.token;
  let param = {
    number: number,
    status: status,
    token: token,
  };
  addBiaozhun(param).then((rec) => {
    console.log("utils",rec);
    // this.batch_show = false;
    // this.$message({
    //   showClose: true,
    //   message: "修改成功",
    //   type: "success",
    // });
    //立项成功到初审
    this.$router.push({
      path: "../../preliminary/preliminaryExam",
    });
  });
}

// 获取用户权限
export function getUserInfo() {
  let params = {
    token: this.token,
  };
  userInfoApi(params).then((res) => {
    this.isAdmin = res.data.type;
  });
}

// 时间格式化
export function momentBytime(date) {
  let y = moment(date).format("YYYY-MM-DD");
  return y;
}

// 生成guid
export function generateGUID() {
  return 'xxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 8 | 0,
        v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(8);
  });
}