// 所有的过滤器

export function formatDate(timestamp, fmt) {
  if (fmt == undefined || fmt == "" || fmt == null) {
    fmt = "yyyy-MM-dd ";
  }
  if (!timestamp) {
    return "";
  }
  let date = new Date(timestamp);
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, date.getFullYear());
  }
  let obj = {
    "M+": date.getMonth() + 1,
    "d+": date.getDate(),
    "h+": date.getHours(),
    "m+": date.getMinutes(),
    "s+": date.getSeconds(),
  };
  for (let key in obj) {
    let pat = `(${key})`;
    if (new RegExp(pat).test(fmt)) {
      let str = obj[key] + "";
      fmt = fmt.replace(RegExp.$1, ("00" + str).substr(-2));
    }
  }
  return fmt;
}

// 审查状态控制
export function shenchaType(type) {
  if (type == 0) {
    return "退回";
  } else if (type == 1 || type == 4) {
    return "初审";
  } else if (type == 2 || type == 5) {
    return "复审";
  } else if (type == 3 || type == 6) {
    return "终审";  
  } else if (type == 7) {
    return "最终意见";
  }
}

// 手机号验证
export function checkPhone(value, callback) {
  //
  if (!value) {
    return callback(new Error("手机号不能为空"));
  } else {
    const reg = /^1[3456789]\d{9}$/;
    if (reg.test(value)) {
      callback();
    } else {
      return callback(new Error("请输入正确的手机号"));
    }
  }
}
