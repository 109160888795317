<template>
    <div>
        <div>
            <div>
                <standFormHead :markcouter="markcouter" @fuzzy-search="fuzzySearch" @clear-query="clearQuery">
                </standFormHead>
            </div>
            <div class="pitable">
                <div class="plus_add suggest" style="float: right;">
                    <el-button v-if="this.$route.name === 'certificationPublicity'" type="primary"
                        @click="addPublicform">新建企业标准公示</el-button>
                    <el-button v-else type="primary" @click="addFrom">新建企业标准公告</el-button>
                </div>
                <el-table :data="standardData" :row-class-name="tabRowClassName" style="width: 100%"
                    :header-cell-style="{ background: '#3082E2', color: '#FFF' }">

                    <el-table-column label="序号" type="index"> </el-table-column>
                    <el-table-column label="状态" prop="status" align="center" width="100px">
                        <template slot-scope="{ row }">
                            <div v-if="!row.status">
                                <el-tag style="background-color:brown;color:white" size="small">未校验</el-tag>
                            </div>
                            <div v-else-if="row.status == 1"><el-tag size="small"
                                    style="background-color: chocolate;color:white">已校验未审核</el-tag></div>
                            <div v-else-if="row.status == 2"><el-tag size="small"
                                    style="background-color:deepskyblue;color:white">已审核</el-tag></div>
                            <div v-if="!row.publish"><el-tag size="small"
                                    style="background-color:blueviolet;color:white">未发布</el-tag></div>
                            <div v-else-if="row.publish == 1"><el-tag size="small"
                                    style="background-color:green;color:white">已发布</el-tag></div>
                        </template>
                    </el-table-column>
                    <el-table-column label="标题" prop="subject" align="left">
                        <template slot-scope="{ row }">
                            <span class="subtext" @click="infoEvent(row)">{{ row.subject }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="创建日期" prop="created_at" align="center" width="130px">
                        <template slot-scope="{ row }">
                            <span>{{
                                row.created_at | formatDate("yyyy-MM-dd")
                            }}</span>
                        </template>
                    </el-table-column>

                    <el-table-column label="预览" prop="" align="center" width="200px">
                        <template slot-scope="{ row }">
                            <div @click="jumpArticle(row)">
                                <qrCode :rowId="row.id"></qrCode>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center" width="150px">
                        <template slot-scope="{ row }">                           

                            <el-tooltip v-if="row.publish == 0" class="item" effect="dark" content="审核" placement="top">
                                <el-button icon="el-icon-setting" type="text" @click="handleCheck(row)"></el-button>
                            </el-tooltip>
                            <el-tooltip class="item" effect="dark" content="撤回" placement="top">
                                <el-button icon="el-icon-refresh-right" type="text" @click="withDraw(row)"></el-button>
                            </el-tooltip>
                            <el-tooltip class="item" effect="dark" content="删除" placement="top">
                                <el-button icon="el-icon-delete" type="text" style="color: red;"
                                    @click="handleDelete(row)"></el-button>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <!-- 分页 -->
            <div class="pageTotal">
                <div class="block">
                    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                        :current-page="page" layout="prev, pager, next" :total="totalCount" :page-size="limit">
                    </el-pagination>
                </div>
            </div>
        </div>
        <div>
            <el-dialog :visible.sync="detailVisible" custom-class="preview_dialog">
                <div>
                    <qrCode :rowId="rowData == null ? '' : rowData.id" class="big_code"></qrCode>
                </div>
                <div class="img_top"><el-link @click="seeDetail()" :underline="false">点击查看</el-link></div>
                <div slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="detailVisible = false">确 定</el-button>
                </div>
            </el-dialog>
        </div>
        <!-- 审核校对对话框 -->
        <div>
            <el-dialog title="校对审核" :visible.sync="checkVisible">
                <el-form :model="editForm">
                    <el-form-item label="意见">
                        <el-input v-model="idea" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="是否通过">
                        <el-switch v-model="editForm.status"></el-switch>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="checkVisible = false">取 消</el-button>
                    <el-button type="primary" @click="CheckSubmit">确 定</el-button>
                </div>
            </el-dialog>
        </div>

        <div>
            <!-- 查看详情页面 -->
            <infodetails ref="infoDetailRef" :getForm="getForm" :logData="logData"></infodetails>
        </div>

        <div>
            <!-- 公示添加模板页面 -->
            <addviceForm ref="addviceFormRef"></addviceForm>
        </div>
        <div>
            <!-- 公告添加模板页面 -->
            <addPublic ref="addpublicRef"></addPublic>
        </div>
    </div>
</template>
<script>
import {
    organizeList, publishList, publishAdd, publishDel, queryInfo, organizationsInfo,
    publishEdit, publishClose, publishCheck, examineNews, publishNews, cacheOrganize,

} from "@api/enterprise";
import standFormHead from "./standFormHead"
import uploadFile from "./uploadFile"
import richTextEdit from "./richTextEdit"
import qrCode from "./qrBarCode"
import infodetails from "./InfoDetails"
import standardFile from "./standardFile"
import AddviceForm from "./addviceForm";
import { formatDate } from '@utils/filters';
import addPublic from "./addPublic.vue";
export default {
    name: 'publicannounce',
    components: { standFormHead, uploadFile, richTextEdit, qrCode, infodetails, standardFile, AddviceForm, addPublic },
    filters: {
        formatDate
    },
    props: {
        typeid: Number,//设置当前公示类型
    },
    data() {
        return {

            qrcodeUrl: "@assets/QRCode.png",
            text: '',
            qrcode: null,
            submenu: "standard_release",
            value: "文字",
            size: 300,
            markcouter: "",
            standardData: [],
            editDialogVisible: false,
            editForm: {
                subject: "",
                short_subject: "",
                category_id: "",
                tig: "",
                organization_id: '',
                bumen: '',
                status: '',
                publish: '',
                wxdate: '',
                photo: '',
                content: '',
                files: [], //上传文件
                updated_at: '',
                created_at: '',
                check: '',
                url: '',
                stick: "1",
                sheng: false,
                idea: ''

            },
            filterTypeOptions: [
                {
                    label: '企业标准发布（公告）',
                    value: 12,
                },
                {
                    label: '企业标准发布（公示）',
                    value: 16,
                },
                {
                    label: '企业标准发布（文件）',
                    value: 18,
                },
            ],
            contentTypeOptions: [
                {
                    label: '最新新闻',
                    value: 1,
                },
                {
                    label: '科技动态',
                    value: 2,
                },
                {
                    label: '最新政策',
                    value: 3,
                },
                {
                    label: '国内新闻',
                    value: 4,
                },
                {
                    label: '国际科技',
                    value: 5,
                },
                {
                    label: '国内科技',
                    value: 6,
                },
            ],
            organizationOptions: [],
            deptOptions: [
                {
                    label: '综合部',
                    value: 1,
                },
                {
                    label: '标准化部',
                    value: 2,
                },
                {
                    label: '绿色建筑部',
                    value: 3,
                },
                {
                    label: '建筑产业部',
                    value: 4,
                },
                {
                    label: '评价标识部',
                    value: 5,
                },
            ],
            fileType: 'image',
            page: 1,
            limit: 15,
            totalCount: 0,
            totalPage: 1,
            token: "",
            richcontent: "",
            detailVisible: false,
            rowData: null,
            checkVisible: false,
            auditSteps: 0,
            getForm: null,
            logData: [],
            isAdd: false,
            tid: '',
            idea: ""
        }
    },
    created() {

        // 这里用于企业标准数据筛选筛选数据
        if (this.$props.typeid === undefined) {
            this.tid = '12'
        } else {
            this.tid = this.$props.typeid
        }
    },

    mounted() {
        this.token = this.$store.state.token;
        this.getPublishData();
        this.enterpriseData();
    },
    methods: {
        //校对审核
        CheckSubmit() {

            const submitForm = {
                id: this.getForm.id,
                pass: '1',
                idea: this.idea,
                token: this.token,
                source: 4
            };

            // 校对
            if (this.getForm.status == 0 && this.getForm.publish == 0) {

                publishCheck(submitForm).then((res) => {
                    if (res.code == 200) {
                        this.getPublishData();
                        this.idea = ''
                        this.checkVisible = false
                    }
                });
            } else if (this.getForm.status == 1) {
                // 审核
                examineNews(submitForm).then((res) => {
                    if (res.code == 200) {
                        this.getPublishData();
                        this.idea = ''
                        this.checkVisible = false
                    }
                });
            } else if (this.getForm.status == 2 && this.getForm.publish == 0) {
                // 发布

                publishNews(submitForm).then((res) => {
                    if (res.code == 200) {
                        this.getPublishData();
                        this.idea = ''
                        this.checkVisible = false
                    }
                });
            }

        },
        //搜索
        fuzzySearch(formdata) {
            this.page = 1
            this.getPublishData(formdata)
        },
        clearQuery() {
            this.getPublishData()
        },
        // 获取数据
        getPublishData(formdata) {
            let data = {
                token: this.$store.state.token,
                page: this.page,
                limit: this.limit,
                type: formdata != undefined && formdata.type != '' ? formdata.type : this.tid,
                name: formdata === undefined ? undefined : formdata.name,
                tig: formdata != undefined && formdata.tig != '' ? formdata.tig : undefined,
                status: formdata != undefined && formdata.status != '' ? formdata.status : undefined,
                publish: formdata != undefined && formdata.publish != '' ? formdata.publish : undefined,
            }
            publishList(data).then(rec => {
                this.standardData = rec.data
                this.page = rec.page //当前页
                this.totalCount = rec.totalCount
                this.totalPage = rec.totalPage
                this.limit = rec.limit
            })
        },
        //获取文件的数据
        getFileData(obj) {
            this.editForm.photo = obj.photo
            this.editForm.files = obj.files
        },
        //富文本双向绑定
        contentChange(content) {
            this.richcontent = content
        },
        tabRowClassName({ rowIndex }) {
            let index = rowIndex + 1;
            if (index % 2 == 0) {
                return 'warning-row'
            }
        },
        //切换子菜单
        subMenuHandle() {
            if (this.submenu == 'standard_release') {
                //标准发布
                this.page = 1
                this.getPublishData()
            } else {
                this.$refs.standDocument.page = 1
                this.$refs.standDocument.queryStandard()
            }
        },
        //新增公告
        addFrom() {
            //新建公开意见
            this.$refs.addpublicRef.adviceDialogVisible = true

        },
        // 新建公示
        addPublicform() {
            this.$refs.addviceFormRef.adviceDialogVisible = true
        },
        //查询企业
        enterpriseData() {
            let data = {
                page: 1,
                limit: 50,
                token: this.$store.state.token//获取cookie字符串
            }
            organizeList(data).then(rec => {
                // debugger
                this.organizationOptions = rec.data
            })
        },
        handleSizeChange(page) {
            this.limit = page;
            this.getPublishData();
        },
        handleCurrentChange(page) {
            this.page = page;
            this.getPublishData();
        },
        //打开详情
        handleUpdate(row) {
            this.editDialogVisible = true
            this.isAdd = false

            queryInfo({ token: this.token, id: row.id }).then(rec => {
                this.editForm = { ...rec.data }

                this.editForm.stick = String(rec.data.stick)
                this.editForm.organization_id = rec.data.id
                if (rec.data.sheng == 1) {
                    this.editForm.sheng = false
                } else if (rec.data.sheng == 2) {
                    this.editForm.sheng = true
                }
                this.$refs.richTextRef.content = rec.data.content
                //图片的回显
                this.$refs.uploadimg.fileList = []
                if (rec.data.photo != '') {
                    this.$refs.uploadimg.fileList.push({ url: rec.data.photo })
                } else {
                    return
                }
            })
            this.organizeData(row)
        },
        //标题查看资讯详情
        infoEvent(row) {
            this.$refs.infoDetailRef.infoDetailVisible = true
            this.infoDetailData(row)
        },
        //查询资讯详情
        infoDetailData(row) {
            let params = {
                id: row.id,
                token: this.token
            }
            queryInfo(params).then(rec => { 
                this.getForm = rec.data
                this.logData = rec.data.log
            })
        },
        //校对与审核
        handleCheck(row) {
            this.getForm = row
            this.checkVisible = true
        },
        organizeData(row) {
            organizationsInfo({ token: this.token, id: row.id }).then(rec => {
                // console.log('查询组织详情的结果', rec.data)
                const obj = {
                    name: rec.data.name,
                    id: rec.data.id,
                };
                // console.log('打印要添加的对象内容', obj)
                this.organizationOptions.push(obj)
            })
        },
        handleDelete(row) {
            this.$confirm('是否确认删除?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                publishDel({ token: this.token, id: row.id, source: 4 }).then(rec => {
                    // debugger
                    // console.log('返回的结果', rec)
                    if (rec.code == 200) {
                        this.$message({
                            type: "success",
                            message: rec.message
                        })
                        this.getPublishData();
                        this.editDialogVisible = false
                    }
                })
            }).catch(() => {
                // this.$message({
                //   type: 'info',
                //   message: '已取消删除'
                // });
            });
        },
        //暂存表单
        saveEdit(formName) {
            let randomId = Math.floor(Math.random() * 1000)
            // console.log(randomId, '打印随机数字')
            this.editForm.content = this.richcontent;
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    //暂存
                    let data = {
                        ...this.editForm,
                        sheng: this.editForm.sheng == false ? 1 : 2,
                        stick: Number(this.editForm.stick),
                        id: randomId
                    }
                    localStorage.setItem('casheId', randomId);
                    cacheOrganize(data).then(rec => {
                        // debugger
                        console.log('返回暂存的结果', rec)
                        if (rec.code == 200) {
                            this.$message({
                                type: "success",
                                message: rec.message
                            })
                            this.getPublishData();
                            this.editDialogVisible = false
                        }
                    })
                } else {
                    // console.log('error submit!!');
                    return false;
                }
            });
        },
        //提交表单
        submitEdit(formName) {
            // console.log(this.editForm.id, '打印表单数据')
            // console.log(this.richcontent)
            this.editForm.token = this.token;
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.editForm.token = this.token;
                    this.editForm.content = this.richcontent;
                    let params = {
                        ...this.editForm,
                        sheng: this.editForm.sheng == false ? 1 : 2,
                        stick: Number(this.editForm.stick)
                    }
                    // console.log(params, '打印提交的表单数据')
                    if (this.editForm.id === undefined) {
                        //新增
                        publishAdd(params).then(rec => {
                            // debugger
                            // console.log('返回的结果', rec)
                            if (rec.code == 200) {
                                this.$message({
                                    type: "success",
                                    message: rec.message
                                })
                                this.getPublishData();
                                this.editDialogVisible = false
                            }
                        })
                    } else if (this.editForm.id != undefined) {
                        let data = {
                            ...this.editForm,
                            sheng: this.editForm.sheng == false ? 1 : 2,
                        }
                        publishEdit(data).then(rec => {
                            // debugger
                            // console.log('修改的结果', rec)
                            if (rec.code == 200) {
                                this.$message({
                                    type: "success",
                                    message: rec.message
                                })
                                this.getPublishData();
                                this.editDialogVisible = false
                            }
                        })
                    }
                } else {
                    // console.log('error submit!!');
                    return false;
                }
            });
        },
        //撤回
        withDraw(row) {
            this.$confirm('是否确认撤回?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let params = {
                    token: this.token,
                    id: row.id
                }
                publishClose(params).then(rec => {

                    if (rec.code == 200) {
                        this.$message({
                            type: "success",
                            message: rec.message
                        })
                        this.getPublishData();
                    }
                })
            }).catch(() => {

            });
        },
        //取消
        cancleEdit() {
            this.editDialogVisible = false
            this.$refs.richTextRef.content = ''
            this.$refs.editForm.resetFields();
        },
        jumpArticle(row) {
            this.detailVisible = true
            this.rowData = row
        },
        seeDetail() {
            window.open("https://gbservice.cn/#/detailbk?id=" + this.rowData.id);
        },
        //资讯取消
        infoCancle() {
            this.checkVisible = false
        },

    },
}
</script>
<style scoped>
/deep/.el-button--primary {
    background-color: #3082e2;
    border-color: #3082e2;
}

/deep/.el-button--primary:hover {
    background-color: #409EFF;
    border-color: #409EFF;
}

/deep/.el-input__suffix {
    right: 43px;
    transition: all .3s;
    border-left: 1px solid #c0c4cc;
}

/deep/.el-table__row .el-table__row--striped {
    background-color: rgba(48, 130, 226, 0.1);
}

/deep/ .el-table th.el-table__cell>.cell {
    min-width: 100px;
}

/deep/ .el-table th.el-table__cell.is-leaf,
/deep/ .el-table td.el-table__cell {
    border-bottom: 0;
}

/deep/.el-form-item__label {
    font-family: Adobe Heiti Std;
    color: #2D2D2D;
    width: 80px;
}

/deep/ .litop .el-input {
    width: 245px;
}

/deep/ .litop .el-input__inner {
    width: 204px;
}

/deep/ .el-table th.el-table__cell>.cell {
    font-size: 16px;
}

/deep/.el-table .warning-row {
    background: rgba(48, 130, 226, 0.1);
}

/deep/ .el-link.el-link--primary {
    padding-right: 30px !important;
}

.pitable {
    /*height: 60vh;*/
}

.litop {
    margin: 20px 24px;
}

.litop span {
    color: #3086fb;
    margin-left: 10px;
    line-height: 40px;
}

.pageTotal {
    display: flex;
    flex-direction: row-reverse;
    margin: 30px 0;
}

.prbtn {
    display: flex;
    flex-direction: row-reverse;
}

.linkto {
    text-decoration: none;
    color: #3082E2;
}

.linkto>span:hover {
    color: #84b5ec;
}

/* 新改 */
/* .zhidingsearch {
    background-color: #ffffff;
    padding: 1px 15px;
    margin: 20px 0 10px 0;
} */

.piconetn {
    background-color: #ffffff;
    padding: 20px;
    box-shadow: 0px 3px 9px 1px #EEEEEE;
}

.form_line {
    display: flex;
}

.form_line .el-form-item {
    width: 50%;
    margin: 0 16px 24px 0;
}

.form_line .el-form-item .el-input,
.el-select {
    width: 80%;
}

.plus_add {
    margin: 20px 0 20px 0;
}

.agree {
    margin-left: 18px;
}

/deep/ .preview_dialog {
    width: 500px;
}

.preview_dialog .img_top {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* .preview_dialog .prev_img {
    vertical-align: middle;
    width: 210px;
    height: 210px;
} */

/deep/ .preview_dialog .el-link--inner {
    color: #0099fc;
}

/* .qrcode_img {
    vertical-align: middle;
    width: 50px;
    height: 50px;
} */

/deep/ .news_dialog {
    width: 70%;
}

/deep/ .news_dialog .el-dialog__header {
    text-align: center;
}

/deep/ .el-step__head.is-finish,
/deep/ .el-step__head.is-process {
    width: 320px;
}

/deep/ .big_code img {
    vertical-align: middle;
    width: 210px;
    height: 210px;
    margin-bottom: 11px;
}

/deep/ .form_line .el-input__suffix {
    border: none;
    right: 16px;
}
</style>
<style scoped lang="scss">
.time_status {
    display: flex;
    justify-content: space-between;

    .show_text {
        width: 60%;
        display: flex;
        justify-content: space-between;

        .bottom_txt {
            width: 100%;
            display: flex;
            justify-content: space-between;

            .create_date {
                padding-top: 6px;
            }
        }
    }

    .form_right {
        width: 50%;
        margin-top: 26px;
        margin-left: 13%;

        .text_word {
            margin: 16px 0 16px 0;
        }
    }
}

.double_add {
    display: flex;
    float: right;

    .suggest {
        margin-left: 18px;
    }
}

.subtext {
    color: #0099fc;
    cursor: pointer;
}
</style>