<template>
  <div>
    <div v-if="this.$route.name != 'certificationPublicity'" class="pititle">
      <ManagerList></ManagerList>
    </div>
    <div v-else class="pititle">
      <el-tabs v-model="activeTitle">
        <el-tab-pane label="认证前审核" name="check">
          <ManagerList></ManagerList>
        </el-tab-pane>
        <el-tab-pane label="公示管理" name="publicity">
          <publicAnnounce :typeid="16"></publicAnnounce>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import ManagerList from "./managerList"; //通过外部引入处理
import publicAnnounce from "./components/publicAnnounce";

export default {
  components: { ManagerList, publicAnnounce },
  data() {
    return {
      activeTitle: "check",  
 
    };
  }
};
</script>
