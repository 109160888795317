<template>
    <div class="info_contain">
        <el-dialog :visible.sync="infoDetailVisible" custom-class="info_dialog" title="公开信息" :getForm="getForm">
            <div class="check_content">
                <div class="time_status">
                    <div class="show_text">
                        <div>
                            <h1>{{ getForm == null ? '' : getForm.subject }}</h1>
                            <!-- <h3>{{ getForm == null ? '' : getForm.short_subject }}</h3> -->
                            <div class="bottom_txt">
                                <div class="create_date">{{ getForm == null ? '' : getForm.created_at || '-' }}</div>
                                <div class="right_label">
                                    <el-tag type="danger" v-if="getForm != null && getForm.status == 0">未校验</el-tag>
                                    <el-tag key="未审核" type="warning"
                                        v-else-if="getForm != null && getForm.status == 1">未审核</el-tag>
                                    <el-tag key="未发布" type="info"
                                        v-else-if="getForm != null && getForm.status == 2 && getForm.publish == 0">未发布</el-tag>
                                    <el-tag key="已发布" type="success" v-else>已发布</el-tag>
                                </div>
                            </div>
                            <div v-html="getForm == null ? '' : getForm.content" class="appendix"></div>
                        </div>
                    </div>
                    <div class="form_right">
                        <label>审核过程</label>

                        <el-steps direction="vertical" :active="logData.length" class="newsteps">
                            <el-step v-for="(item, index) in logData" :key="index" :title="item.created_at"
                                :description="item.status + `者:` + item.name">
                                <template slot="icon">
                                    <svg-icon data_iconName="circle" className="circle_icon" />
                                </template>
                            </el-step>
                        </el-steps>

                    </div>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="infoDetailVisible = false">关闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>

export default {
    name: 'infodetails',
    data() {
        return {
            infoDetailVisible: false,
        }
    },
    props: {
        getForm: {
            type: Object
        },
        logData: {
            type: Array
        }
    }

}
</script>
<style scoped>
/deep/ .info_dialog {
    width: 70%;
}

/deep/ .info_dialog .el-dialog__header {
    text-align: center;
}

/deep/ .newsteps .el-step__icon.is-text {
    border: none;
}

/deep/ .newsteps .el-step__head.is-finish {
    width: auto;
}

.newsteps .el-step.is-vertical {
    padding-bottom: 11px;
}

.info_contain .el-steps--vertical {
    height: auto;
}

/deep/ .appendix img {
    vertical-align: middle;
    max-width: 100%;
    height: auto;
}

/deep/ .info_dialog .MsoTableGrid {
    width: 623pt;
}
</style>
<style scoped lang="scss">
.time_status {
    display: flex;
    justify-content: space-between;

    .show_text {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .bottom_txt {
            width: 100%;
            display: flex;
            justify-content: space-between;

            .create_date {
                padding-top: 6px;
            }
        }
    }

    .form_right {
        width: 40%;
        margin-top: 26px;
        margin-left: 13%;
        .text_word {
            margin: 16px 0 16px 0;
        }
    }
}

// .info_contain {
.appendix {
    max-width: 100%;

    img {
        vertical-align: middle;
        width: 100%;
    }
}

// }</style>