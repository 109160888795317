<template>
  <div>
    <!-- 这里的搜索栏可以构件化 -->
    <div class="zhidingsearch">
      <el-form>
        <div class="litop">
          <el-row>
            <el-col :span="5">
              <div class="grid-content bg-purple">
                <el-form-item label="标准名称">
                  <el-input v-model="proName" placeholder="请输入您的标准名称" clearable
                    @keyup.enter.native="search()"></el-input>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="5">
              <div class="grid-content bg-purple-light">
                <el-form-item label="编制企业">
                  <el-input v-model="firstUnit" placeholder="请输入您的编制企业" clearable
                    @keyup.enter.native="search()"></el-input>
                </el-form-item>

              </div>
            </el-col>
            <el-col :span="5">
              <div class="grid-content bg-purple">
                <el-form-item label="标准类型">
                  <el-select v-model="biaozhunType" placeholder="标准类型" clearable @keyup.enter.native="search()">
                    <el-option label="标准" :value="1"></el-option>
                    <el-option label="标准设计" :value="2"></el-option>
                    <!-- <el-option label="推荐行技术规程" :value="3"></el-option> -->
                  </el-select>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="5">
              <div class="grid-content bg-purple-light">
                <el-form-item label="制定类型">
                  <el-select v-model="zhidingType" placeholder="制定类型" clearable @keyup.enter.native="search()">
                    <el-option label="制定" :value="1"></el-option>
                    <el-option label="修订" :value="2"></el-option>
                  </el-select>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="4">
              <div class="grid-content bg-purple">
                <el-button type="primary" @click="search">查询</el-button>
                <el-button type="info" @click="qingkong">清空</el-button>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-form>
    </div>
    <div class="piconetn">
      <!-- 表格 -->
      <div class="pitable">
        <el-table :data="tableData" :row-class-name="tabRowClassName" style="width: 100%"
          :header-cell-style="{ background: '#3082E2', color: '#FFF' }">
          <el-table-column label="序号" type="index"> </el-table-column>

          <el-table-column label="标准名称" prop="proName" align="left">
            <template slot-scope="{row}">
              <router-link :to="gotoStep(row)" class="linkto">
                <span>{{ row.proName }}</span>
              </router-link>
            </template>
          </el-table-column>         
          <el-table-column label="编制企业" prop="firstUnit" align="center">
          </el-table-column>
          <el-table-column label="标准类型" align="center" width="130px">
            <template slot-scope="{ row }">
              <span v-if="row.biaozhunType == 1">标准</span>
              <span v-if="row.biaozhunType == 2">标准设计</span>
            </template>
          </el-table-column>
          <el-table-column label="制定类型" align="center" width="130px">
            <template slot-scope="{ row }">
              <span v-if="row.zhidingType == 1">制定</span>
              <span v-if="row.zhidingType == 2">修订</span>
            </template>
          </el-table-column>

          <el-table-column label="申请日期" prop="created_at" align="center" width="130px">
            <template slot-scope="{ row }">
              <span>{{
                row.created_at | formatDate("yyyy-MM-dd")
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="受理日期" prop="updated_at" align="center" width="130px">
            <template slot-scope="{ row }">
              <span>{{
                row.updated_at | formatDate("yyyy-MM-dd")
              }}</span>
            </template>
          </el-table-column>
          
          <el-table-column label="状态" prop="proName" align="left" width="200px">
            <template slot-scope="{row}">
              <el-tag size="mini" :style="{ 'background-color': getStatusColor(row.status), 'color': 'white' }">{{
                getStatusText(row.status) }}</el-tag>
            </template>
          </el-table-column>
          
          <el-table-column v-if="this.$route.name === 'spotCertification'" label="操作" align="center" width="150px">
            <template slot-scope="{ row }">
              <el-tooltip v-if="row.status == '5.00'" class="item" effect="dark" content="是否抽签" placement="top">
                <i class="el-icon-magic-stick" @click="selectcheck(row)" style="color: blue;"></i>
              </el-tooltip>
              <el-tooltip v-else class="item" effect="dark" content="已抽检，如需撤回请联系超级管理员！" placement="top">
                <i class="el-icon-magic-stick" style="color: gray;"></i>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column v-if="this.$route.name === 'expertCertification'" label="操作" align="center" width="150px">
            <template slot-scope="{ row }">
              <el-tooltip v-if="row.status == '6.00'" class="item" effect="dark" content="是否专家认证" placement="top">
                <i class="el-icon-folder-checked" @click="expertcheck(row)" style="color: blue;"></i>
              </el-tooltip>
              <el-tooltip v-else class="item" effect="dark" content="已进行专家认证，无法再提交！" placement="top">
                <i class="el-icon-folder-checked" style="color: gray;"></i>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!-- 分页 -->
      <div class="pageTotal">
        <div class="block">
          <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="page" layout="prev, pager, next" :total="totalCount" :page-size="limit">
          </el-pagination>
        </div>
      </div>

      <div>
        <el-dialog v-if="this.$route.name === 'spotCertification'" title="确定抽检验证" :visible.sync="dialogVisible"
          width="30%" :before-close="handleClose">
          <span>确定对—<strong>{{ dialogrow.proName }}</strong>—进行抽检验证？</span>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="sureSubmit(dialogrow, '5.05')">确 定</el-button>
          </span>
        </el-dialog>
        <el-dialog v-else title="确定专家认证" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
          <span>确定对—<strong>{{ dialogrow.proName }}</strong>—进行专家认证？</span>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="sureSubmit(dialogrow, '6.05')">确 定</el-button>
          </span>
        </el-dialog>
      </div>

    </div>
  </div>
</template>

<script>
import { entBiaozhunList, enterpriseExamine } from "@api/project";
import { statusList } from "@api/enterprise";
import { formatDate } from "@utils/filters";

export default {

  filters: {
    formatDate
  },
  data() {
    return {
      dialogrow: {},
      dialogVisible: false,
      //选中的表格数据
      selectedProjects: [],
      form: {},
      page: 1,
      limit: 10,
      totalCount: 0,
      totalPage: 1,
      tableData: [],
      token: '',
      firstUnit: '',
      proName: '',
      zhidingType: '',
      biaozhunType: '',
      statusMap: {},
      statusText: "", //搜索状态的文本,
      statusfilter: {
        "preliminaryExam": '2.10,2.20,2.30,2.40,2.50,2.60',
        "technicalIndex": '3.05,3.10,3.20,3.30,3.40,3.50,3.60',
        "certificationPublicity": '4.05,4.10,4.20,4.30,4.40,4.50,4.60',
        "spotCertification": '5.00,5.05,5.10,5.20,5.30,5.40,5.50,5.60',
        "expertCertification": '6.00,6.05,6.10,6.20,6.30,6.40,6.50,6.60'
      },
      editForm: {
        subject: "",
        short_subject: "",
        category_id: "",
        tig: "",
        organization_id: '',
        bumen: '',
        status: '',
        publish: '',
        wxdate: '',
        photo: '',
        content: '',
        files: [], //上传文件
        updated_at: '',
        created_at: '',
        check: '',
        url: '',
        stick: "1",
        sheng: false
      },

    };
  },
  computed: {
    routePath() {
      return this.$route.name;
    }
  },
  watch: {
    // 这里通过路由区分不同的列表
    routePath(newPath) {
      if (newPath === 'preliminaryExam') {
        this.statusText = this.statusfilter.preliminaryExam;
        this.getList(this.statusText);
      } else if (newPath === 'technicalIndex') {
        //  专家这里调整成分开审核，互相不可见，已通过后端筛选
        this.statusText = this.statusfilter.technicalIndex;
        this.getList(this.statusText);
      } else if (newPath === 'certificationPublicity') {
        this.statusText = this.statusfilter.certificationPublicity;
        this.getList(this.statusText);
      } else if (newPath === 'spotCertification') {
        this.statusText = this.statusfilter.spotCertification;
        this.getList(this.statusText);
      } else if (newPath === 'expertCertification') {
        this.statusText = this.statusfilter.expertCertification;
        this.getList(this.statusText);
      }
    }
  },
  mounted() {
    this.getToken();
    this.fetchStatusList();
    if (this.$route.name === 'preliminaryExam') {
      this.statusText = this.statusfilter.preliminaryExam;
      this.getList(this.statusText);
    } else if (this.$route.name === 'technicalIndex') {
      this.statusText = this.statusfilter.technicalIndex;

      this.getList(this.statusText);
    } else if (this.$route.name === 'certificationPublicity') {
      this.statusText = this.statusfilter.certificationPublicity;
      this.getList(this.statusText);
    } else if (this.$route.name === 'spotCertification') {
      this.statusText = this.statusfilter.spotCertification;
      this.getList(this.statusText);
    } else if (this.$route.name === 'expertCertification') {
      this.statusText = this.statusfilter.expertCertification;
      this.getList(this.statusText);
    }

  },
  methods: {

    //专家认证审核 
    expertcheck(row) {
      this.dialogrow = row;
      this.dialogVisible = true;
    },

    // 抽检验证
    selectcheck(row) {
      this.dialogrow = row;
      this.dialogVisible = true;
    },

    // submit 抽检验证
    sureSubmit(row, status) {
      // 定义提交参数
      let params = {
        token: this.$store.state.token,
        sid: row.id,
        status: status,
        type: "1",
        after_status: status,
        examinetype: 0
      };
      enterpriseExamine(params)
        .then((res) => {
          if (this.$route.name === 'spotCertification') {
            this.$message.success("该标准已被抽检！");
          } else if (this.$route.name === 'expertCertification') {
            this.$message.success("该标准需要进行专家认证！");
          }
          this.dialogVisible = false;
        }).catch((err) => {
          this.dialogVisible = false;
        });

    },
    // 方式异常关闭
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done();
        })
        .catch(_ => { });
    },

    // 获取数据列表
    fetchStatusList() {
      try {
        let param = {
          page: this.page,
          limit: 100,
          token: this.token,
        };
        statusList(param)
          .then((res) => {
            this.statusMap = this.generateStatusMap(res.data.data);
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        console.error(error);
      }
    },
    generateStatusMap(statusl) {
      const statusMap = {};
      statusl.forEach(item => {
        statusMap[item.number] = { "rgb": item.rgb, "name": item.name }; // 将状态与颜色进行映射
      });
      return statusMap;
    },
    getStatusColor(status) {
      return this.statusMap[status].rgb; // 获取状态对应的颜色，如果不存在则返回空字符串
    },
    getStatusText(status) {
      return this.statusMap[status].name;
    },
    getToken() {
      this.token = this.$store.state.token//获取cookie字符串
      // this.getbiaozhunList()
    },
    tabRowClassName({ rowIndex }) {
      let index = rowIndex + 1;
      if (index % 2 == 0) {
        return 'warning-row'
      }
    },
    //获得表格数据
    getList(statusText) {
      let data = {
        limit: this.limit,
        page: this.page,
        token: this.token,
        proName: this.proName,
        firstUnit: this.firstUnit,
        zhidingType: this.zhidingType,
        biaozhunType: this.biaozhunType,
        status: statusText
      }
      entBiaozhunList(data).then(rec => {
        this.tableData = rec.data
        this.limit = rec.limit
        this.page = rec.page
        this.totalCount = rec.totalCount
        this.totalPage = rec.totalPage
      })
    },
    //搜索
    search() {
      this.page = 1;
      this.getList();
    },
    //清空
    qingkong() {
      this.firstUnit = '',
        this.proName = '',
        this.zhidingType = '',
        this.biaozhunType = '',
        this.getList();
    },
    // 分页
    handleSizeChange(page) {
      this.limit = page;
      this.getList();
    },
    handleCurrentChange(page) {
      this.page = page;
      this.getList();
    },

    gotoStep(row) {
      // 这里可以切换路由
      if (this.$route.name === 'preliminaryExam') {
        let url = '/preliminary/Draftreview?id=' + row.id;
        return url;
      } else if (this.$route.name === 'technicalIndex') {
        let url = '/technology/Draftreview?id=' + row.id;
        return url;
      } else if (this.$route.name === 'certificationPublicity') {
        let url = '/certification/Draftreview?id=' + row.id;
        return url;
      } else if (this.$route.name === 'spotCertification') {
        let url = '/spotcheck/Draftreview?id=' + row.id;
        return url;
      } else if (this.$route.name === 'expertCertification') {
        let url = '/experts/Draftreview?id=' + row.id;
        return url;
      }

    },

  },
};
</script>
<style scoped>
/deep/.el-button--primary {
  background-color: #3082e2;
  border-color: #3082e2;
}

/deep/.el-button--primary:hover {
  background-color: #409EFF;
  border-color: #409EFF;
}

/deep/.el-input__suffix {
  right: 43px;
  transition: all .3s;
  border-left: 1px solid #c0c4cc;
}

/deep/.el-table__row .el-table__row--striped {
  background-color: rgba(48, 130, 226, 0.1);
}

/deep/ .el-table th.el-table__cell>.cell {
  min-width: 100px;
}

/deep/ .el-table th.el-table__cell.is-leaf,
/deep/ .el-table td.el-table__cell {
  border-bottom: 0;
}

/deep/.el-form-item__label {
  font-family: Adobe Heiti Std;
  color: #2D2D2D;
}

/deep/ .litop .el-input {
  width: 245px;
}

/deep/ .litop .el-input__inner {
  width: 204px;
}

/deep/ .el-table th.el-table__cell>.cell {
  font-size: 16px;
}

/deep/.el-table .warning-row {
  background: rgba(48, 130, 226, 0.1);
}

.pitable {
  /*height: 60vh;*/
}

.litop {
  margin: 20px 24px;
}

.litop span {
  color: #3086fb;
  margin-left: 10px;
  line-height: 40px;
}

.pageTotal {
  display: flex;
  flex-direction: row-reverse;
  margin: 30px 0;
}

.prbtn {
  display: flex;
  flex-direction: row-reverse;
}

.linkto {
  text-decoration: none;
  color: #3082E2;
}

.linkto>span:hover {
  color: #84b5ec;
}

/* 新改 */
.zhidingsearch {
  background-color: #ffffff;
  padding: 1px 15px;
  margin: 20px 0 10px 0;
}

.piconetn {
  background-color: #ffffff;
  padding: 20px;
  box-shadow: 0px 3px 9px 1px #EEEEEE;
}
</style>
