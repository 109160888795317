<template>
    <div class="addvice_content">
        <el-dialog :visible.sync="adviceDialogVisible" title="新增企业标准标准公告" :close-on-click-modal="false"
            @close="cancleEdit()" custom-class="stand_dialog">
            <el-form :model="adviceForm" ref="adviceForm" class="adviceForm">
                <div class="title">
                    <div class="text">关于发布江苏省工程建设企业技术{{ standardtypename }}《{{ adviceForm.projectid }}》的公告</div>
                </div>
                <div>
                    <el-form-item label="公告号" prop="number"
                        :rules="[{ required: true, message: '请输入公告号', trigger: 'blur' }]" label-width="100px">
                        <el-input v-model="adviceForm.number"></el-input>
                    </el-form-item>


                    <el-form-item label="标准名称" prop="projectname" label-width="100px"
                        :rules="[{ required: true, message: '请选择标准', trigger: 'blur' }]">
                        <el-select v-model="selectedIds" placeholder="请选择" @change="handleSelectionChange">
                            <el-option v-for="item in projectitems" :key="item.id" :label="item.proName"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="企业名称" prop="company"
                        :rules="[{ required: true, message: '请输入企业名称', trigger: 'blur' }]" label-width="100px">
                        <el-input v-model="adviceForm.company"></el-input>
                    </el-form-item>


                    <el-form-item label="公告编号" prop="publishnumber"
                        :rules="[{ required: true, message: '请输入公告编号', trigger: 'blur' }]" label-width="100px">
                        <el-input v-model="adviceForm.publishnumber"></el-input>
                    </el-form-item>

                    <el-form-item label="有效期间" prop="limitdate" label-width="100px">
                        <el-date-picker v-model="adviceForm.limitdate" type="date" placeholder="选择日期">
                        </el-date-picker>
                    </el-form-item>

                    <el-form-item label="发布时间" prop="publishdate" label-width="100px">
                        <el-date-picker v-model="adviceForm.publishdate" type="date" placeholder="选择日期">
                        </el-date-picker>
                    </el-form-item>

                    <el-form-item label="废止信息">
                        <el-switch v-model="adviceForm.delivery"></el-switch>
                    </el-form-item>

                    <el-form-item v-if="adviceForm.delivery" label="旧公告号" prop="publishnumber" label-width="100px">
                        <el-input v-model="adviceForm.oldnumber"></el-input>
                    </el-form-item>
                    <el-form-item v-if="adviceForm.delivery" label="旧标准名称" prop="publishnumber" label-width="100px">
                        <el-input v-model="adviceForm.oldname"></el-input>
                    </el-form-item>

                    <el-form-item v-if="adviceForm.delivery" label="旧标准号" prop="publishnumber" label-width="100px">
                        <el-input v-model="adviceForm.oldstarndnumber"></el-input>
                    </el-form-item>



                    <el-form-item label="上传公告文件" prop="files" label-width="100px">
                        <!-- <el-upload drag class="upload-demo" action="api/public/bupload" multiple accept=".pdf,.jpg,.png,.doc,.docx" :on-exceed="handleExceed"
                            :file-list="vedioList" :http-request="uploadFileEvent">
                            <i class="el-icon-upload"></i>
                            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                            <div class="el-upload__tip" slot="tip">只能上传pdf文件，且不超过2Mb</div>
                        </el-upload> -->
                        <el-upload accept=".pdf,.jpg,.png,.doc,.docx" :before-upload="showLoad" :file-list="files"
                            :on-success="(response, file, fileList) =>
                                changeMaterImg(response, file, fileList)
                                " :show-file-list="false" :on-remove="removeImg" action="api/public/bupload"
                            class="upload-demo">
                            <el-button size="small" type="primary">上传文件 </el-button>
                        </el-upload>
                    </el-form-item>
                    <div class="flexrow cgtop" v-for="(item, index) in files" @click="zhanshi2(item)" :key="index">
                        <div class="cgfleft">
                            <div>
                                <div style="color: #999999" class="filename">
                                    {{ item.name }}
                                </div>
                            </div>
                        </div>
                        <div class="cgfrig" @click="delbtn(index)">删除</div>
                    </div>
                </div>

            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancleEdit()">取 消</el-button>
                <el-button type="primary" @click="submitForm">发布企业标准公告</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>

import { formattedDate } from "@utils/tools";
import { standardModelAdd, entBiaozhunList } from "@api/project";

export default {
    data() {
        return {
            files: [],
            selectedIds: [],
            standardtypename: '', //标准名称处理，标准和标准设计
            adviceDialogVisible: false,
            projectitems: [
            ],
            adviceForm: {
                number: "",
                company: "",
                publishnumber: "",
                projectid: "",
                limitdate: '',
                publishdate: '',
                delivery: false,
                oldnumber: '',
                oldstarndnumber: "",
                idnumber: "",//认证编号
                standrnumber: "", //标准编号


            },
            standardList: [],
            fileObj: null,
        }
    },
    mounted() {
        this.getList()
    },

    methods: {
        changeMaterImg(response, file, fileList) {

            if (this.files && this.files.length > 0) {
                this.files.push(response.data);
                this.files.splice(0, 1);
            } else {
                this.files.push(response.data);
            }

            this.$nextTick(() => {
                this.loadingInstance.close();
            });
        },
        removeImg(file, fileList) {
            console.log(file, fileList);
            this.materImgList1 = fileList;
            this.materImgList2 = fileList;
            this.materImgList3 = fileList;
            this.materImgList4 = fileList;
            this.materImgList5 = fileList;
        },
        delbtn(fileIndex) {
           
                this.files.splice(fileIndex, 1);
           
        },
        // 上传之前进行格式和文件大小限制
        showLoad(file) {
            const fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1);
            const whiteList = ["pdf", "jpg", "png", "doc", "docx"];
            if (whiteList.indexOf(fileSuffix) === -1) {
                this.$message("上传文件只能是 pdf、jpg、png、doc、docx格式", "error");
                return false;
            }
            const isLt2M = file.size / 1024 / 1024 < 16;
            if (!isLt2M) {
                this.$message("上传文件大小不能超过 16MB", "error");
            }
            return isLt2M;
        },
        // 处理标准名称的修改
        handleSelectionChange(selectedIds) {
            this.selectedIds = selectedIds;
            const item = this.projectitems.find(item => item.id === selectedIds);
            this.adviceForm.projectid = item.proName;
            // console.log(item);
            this.adviceForm.company = item.firstUnit;
            this.updateStandardtypename(item.zhidingType);
        },
        updateStandardtypename(type) {
            if (type == 2) {
                this.standardtypename = '标准设计';
            } else if (type == 1) {
                this.standardtypename = '标准';
            } else {
                this.standardtypename = '';
            }
        },
        //获得表格数据
        getList() {
            let data = {
                page: 1,
                limit: 100,
                token: this.$store.state.token,
                status: '7.00'
            }
            entBiaozhunList(data).then(rec => {
                this.projectitems = rec.data
            })
        },
        // 取消发布
        cancleEdit() {
            this.adviceDialogVisible = false
            this.$refs.adviceForm.resetFields();
        },
        // 发布公告
        submitForm() {
            const limittime = formattedDate(this.adviceForm.limitdate);
            const publishdate = formattedDate(this.adviceForm.publishdate);
            // 增加废止信息
            const oldnumber = this.adviceForm.oldnumber; //上次公告号
            const oldname = this.adviceForm.oldname; //旧标准名称
            const oldstarndnumber = this.adviceForm.oldstarndnumber//旧标准编号 
            const idnumber = this.adviceForm.idnumber;  //旧认证编号
            const standrnumber = this.adviceForm.standrnumber;//新标准编号 

            let rbish = ``
            // 这里需要写个判断是否有废除信息
            if (this.adviceForm.delivery) {
                rbish = `<p class="MsoNormal" style="text-indent: 32pt; line-height: 28.5pt; text-align: justify;"><span
        style="mso-spacerun: 'yes'; font-family: 方正仿宋_GBK; font-size: 16.0000pt; mso-font-kerning: 1.0000pt;">江苏省工程建设标准站${oldnumber}公告发布的《${oldname}》${oldstarndnumber}自本公告发布之日起废止。</span>
</p>`            }


            let content = `<p class="MsoNormal" style="text-align: center; line-height: 28.5000pt; mso-line-height-rule: exactly;" align="center">
    <span style="font-family: 方正黑体_GBK; font-size: 16pt;">${this.adviceForm.number}</span>
<p class="MsoNormal"
    style=" mso-spacerun: 'yes'; font-family: 方正仿宋_GBK; mso-hansi-font-family: 仿宋; letter-spacing: 1.0000pt; font-size: 16.0000pt; mso-font-kerning: 1.0000pt;text-indent: 36pt; line-height: 28.5pt; text-align: justify;">
    ${this.adviceForm.company}发布的工程建设企业技术标准《${this.adviceForm.projectid}》${standrnumber}，符合江苏省工程建设企业技术标准认证公告规则，现予公告，编号
            ${idnumber}，有效期截至${limittime}。</span>
            <br>
            ${rbish}
    
    <br><br><br> <span
        style="mso-spacerun: 'yes'; font-family: 方正仿宋_GBK; font-size: 16.0000pt; mso-font-kerning: 1.0000pt;float: right;">江苏省工程建设标准站
        <br>
        <span
            style="mso-spacerun: 'yes'; font-family: 方正仿宋_GBK; font-size: 16.0000pt; mso-font-kerning: 1.0000pt;text-align: right;line-height: 28.5pt;text-indent: 36pt; float: right;">${publishdate}</span>`



            let params = {
                subject: `省工程建设标准站关于发布江苏省工程建设企业技术《${this.adviceForm.projectid}》标准的公告`,
                content: content,
                category_id: 12,
                users: this.$store.state.id,
                token: this.$store.state.token,
            }


            //新增
            standardModelAdd(params).then(rec => {
                if (rec.code == 200) {
                    this.$message({
                        type: "success",
                        message: rec.message
                    })
                    this.adviceDialogVisible = false
                }
            })
        }
    }
}

</script>
<style scoped>
/deep/ .stand_dialog {
    width: 1080px !important;
}

/deep/ .addvice_content .el-form-item__content {
    display: flex;
}

/deep/ .adviceForm .el-form-item__label {
    width: 100px;
}

/deep/ .adviceForm .el-select,
/deep/ .adviceForm .el-input {
    width: 600px;
}
</style>
<style lang="scss" scoped>
.addvice_content {
    .title {
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid #333;
        margin-bottom: 11px;

        .text {
            font-weight: bold;
            font-size: 24px;
            text-align: center;
            padding: 0px 11px 16px 11px;
        }
    }

    .article {
        width: 100%;
        display: flex;
        font-size: 14px;
        text-align: left;
        padding-bottom: 11px;
        padding-top: 4px;

        // line-height: 36px;
        .text {
            padding: 10px 11px 16px 0;
        }

        .stand_name {
            width: 200px;
        }

        span {
            padding-top: 8px;
        }
    }

    .show_text {
        display: flex;
        font-size: 14px;
        text-align: left;
        padding-bottom: 11px;

        span {
            padding-top: 8px;
        }
    }
}
</style>