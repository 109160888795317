<template>
    <div class="addvice_content">
        <el-dialog :visible.sync="adviceDialogVisible" title="新增企业标准认证前公示" :close-on-click-modal="false"
            @close="cancleEdit()" custom-class="stand_dialog" :before-close="handleClose">
            <el-form :model="adviceForm" ref="adviceForm" class="adviceForm">
                <div class="title">
                    <div class="text">江苏省工程建设企业技术{{ standardtypename }}认证前公示</div>
                    <!-- 标准和标准设计 -->
                </div>
                <div>
                    <!-- {{ adviceForm.publishdate }} -->
                    <el-form-item label="选择公示开始时间" prop="date"
                        :rules="[{ required: true, message: '请填写公示日期', trigger: 'blur' }]">
                        <el-date-picker v-model="adviceForm.publishdate" type="date" placeholder="选择公示开始时间"
                            @change="handleDateChange">
                        </el-date-picker>
                    </el-form-item>

                    <el-form-item label="联系地址" prop="address"
                        :rules="[{ required: true, message: '请输入联系地址', trigger: 'blur' }]">
                        <el-input v-model="adviceForm.address"></el-input>
                    </el-form-item>
                    <el-form-item label="联系电话" prop="phone"
                        :rules="[{ required: true, message: '请输入联系电话', trigger: 'blur' }]">
                        <el-input v-model="adviceForm.phone"></el-input>
                    </el-form-item>
                    <el-form-item label="标准名称" prop="projectname"
                        :rules="[{ required: true, message: '请选择标准（可多选）', trigger: 'blur' }]">
                        <el-select v-model="selectedIds" multiple placeholder="请选择" @change="handleSelectionChange">
                            <el-option v-for="item in projectitems" :key="item.id" :label="item.proName"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <!-- {{ standardtypename }} -->

                </div>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancleEdit">取 消</el-button>
                <el-button type="primary" @click="submitForm">发布公告</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>

import uploadFile from "./uploadFile.vue"
import { standardSelect, entBiaozhunList, standardModelAdd } from "@api/project"
import { formatDate } from '@utils/filters';

export default {
    components: { uploadFile },
    filters: { formatDate },
    data() {
        return {
            selectedIds: [],
            standardtypename: '', //标准名称处理，标准和标准设计
            token: '',
            value1: [],
            filteredItems: [],
            showResults: false,
            searchInput: '',
            projectitems: [

            ],
            startandenddate: '',
            adviceDialogVisible: false,
            rowdata: {},
            tableData: [
            ],
            // 需要提交的公示数据
            adviceForm: {
                name: "吴晓春",
                address: "南京市鼓楼区草场门大街88号江苏建设大厦8楼811室（邮编：210036）",
                phone: "025-51868179、51868180",
                date: [],
                projectid: [],
                publishdate: ''
            },
            fileType: "fileWord",
            classifyOptions: [
                {
                    label: '地方标准发布',
                    value: 11,
                },
                {
                    label: '地方标准发布(意见征求)',
                    value: 15,
                },
            ],
            standardList: [],
            fileObj: null,
            allprojectinfo: []
            
        }
    },  
    mounted() {       
        this.getList();
        this.token = this.$store.state.token;      
    },
    methods: {
        
        handleSelectionChange(selectedIds) {
            this.selectedIds = selectedIds;
            this.adviceForm.projectid = selectedIds.map(id => {
                const item = this.projectitems.find(item => item.id === id);
                return { id: item.id, type: item.biaozhunType };
            });
            this.updateStandardtypename();
        },
        updateStandardtypename() {
            const types = this.adviceForm.projectid.map(item => item.type);
            const hasType1 = types.includes(1);
            const hasType2 = types.includes(2);

            if (hasType1 && hasType2) {
                this.standardtypename = '标准与标准设计';
            } else if (hasType2) {
                this.standardtypename = '标准设计';
            } else if (hasType1) {
                this.standardtypename = '标准';
            } else {
                this.standardtypename = '';
            }
        },

        // 计算7天之后的日期
        handleDateChange() {
            if (this.adviceForm.publishdate) {
                const start = new Date(this.adviceForm.publishdate);
                const end = new Date(start);
                end.setDate(start.getDate() + 7);

                const formatDate = (date) => {
                    const year = date.getFullYear();
                    const month = String(date.getMonth() + 1).padStart(2, '0');
                    const day = String(date.getDate()).padStart(2, '0');
                    return `${year}-${month}-${day}`;
                };

                this.formattedStartDate = formatDate(start);
                this.adviceForm.publishdate = this.formattedStartDate;
                this.endDate = formatDate(end);
                this.adviceForm.date = [this.formattedStartDate, this.endDate];
            }
        },

        //获得表格数据
        getList() {
            let data = {
                page: 1,
                limit: 100,
                token: this.$store.state.token,
                status: '3.10' //这里要换成4.90
            }
            entBiaozhunList(data).then(rec => {
                this.projectitems = rec.data
            })           
        },
     

        filterItems() {
            this.filteredItems = this.items.filter(item =>
                item.name.toLowerCase().includes(this.searchInput.toLowerCase())
            );
            this.showResults = this.searchInput.length > 0;
        },
        selectItem(item) {
            this.searchInput = item.name;
            this.showResults = false;
        },
        handleClose(done) {
            this.$confirm('确认关闭？')
                .then(_ => {
                    done();
                })
                .catch(_ => { });
        },

        queryStandards() {
            standardSelect({ token: this.$store.state.token }).then(rec => {
                // debugger
                // console.log('返回的结果', rec)
                this.standardList = rec.data
            })
        },
        // 取消发布
        cancleEdit() {
            this.adviceDialogVisible = false
            this.$refs.adviceForm.resetFields();
        },

        // 异步获取项目的详情信息
        fetchProjectDetails(projectList) {
            for (let i = 0; i < projectList.length; i++) {
                try {
                    const projectId = projectList[i];
                    
                    const item = this.projectitems.find(item => item.id === projectId.id);
                    

                    let projecttable = `<tr style="height: 56.1000pt; page-break-inside: avoid;">
                <td style="width: 49.6500pt; padding: 0.0000pt 5.4000pt 0.0000pt 5.4000pt; border-left: 1.0000pt solid windowtext; mso-border-left-alt: 0.5000pt solid windowtext; border-right: 1.0000pt solid windowtext; mso-border-right-alt: 0.5000pt solid windowtext; border-top: none; mso-border-top-alt: 0.5000pt solid windowtext; border-bottom: 1.0000pt solid windowtext; mso-border-bottom-alt: 0.5000pt solid windowtext;"
                    valign="center" width="66">
                    <p style="text-align: center; line-height: 20.0000pt; mso-line-height-rule: exactly;"
                        align="center"><span
                            style="font-family: 方正仿宋_GBK; mso-hansi-font-family: 仿宋; mso-bidi-font-family: 'Times New Roman'; font-size: 14.0000pt; mso-font-kerning: 1.0000pt;">${i + 1}</span>
                    </p>
                </td>
                <td style="width: 225.6000pt; padding: 0.0000pt 5.4000pt 0.0000pt 5.4000pt; border-left: 1.0000pt solid windowtext; mso-border-left-alt: 0.5000pt solid windowtext; border-right: 1.0000pt solid windowtext; mso-border-right-alt: 0.5000pt solid windowtext; border-top: none; mso-border-top-alt: 0.5000pt solid windowtext; border-bottom: 1.0000pt solid windowtext; mso-border-bottom-alt: 0.5000pt solid windowtext;"
                    valign="center" width="300">
                    <p style="text-align: center; line-height: 20.0000pt; mso-line-height-rule: exactly;"
                        align="center"><span
                            style="font-family: 方正仿宋_GBK; mso-hansi-font-family: 仿宋; mso-bidi-font-family: 'Times New Roman'; font-size: 14.0000pt; mso-font-kerning: 1.0000pt;">${item.proName}</span>
                    </p>
                </td>
                <td style="width: 199.1000pt; padding: 0.0000pt 5.4000pt 0.0000pt 5.4000pt; border-left: 1.0000pt solid windowtext; mso-border-left-alt: 0.5000pt solid windowtext; border-right: 1.0000pt solid windowtext; mso-border-right-alt: 0.5000pt solid windowtext; border-top: none; mso-border-top-alt: 0.5000pt solid windowtext; border-bottom: 1.0000pt solid windowtext; mso-border-bottom-alt: 0.5000pt solid windowtext;"
                    valign="center" width="265">
                    <p style="text-align: center; line-height: 20.0000pt; mso-line-height-rule: exactly;"
                        align="center"><span
                            style="font-family: 方正仿宋_GBK; mso-hansi-font-family: 仿宋; mso-bidi-font-family: 'Times New Roman'; font-size: 14.0000pt; mso-font-kerning: 1.0000pt;">${item.firstUnit}</span>
                    </p>
                </td>
                <td style="width: 249.5000pt; padding: 0.0000pt 5.4000pt 0.0000pt 5.4000pt; border-left: 1.0000pt solid windowtext; mso-border-left-alt: 0.5000pt solid windowtext; border-right: 1.0000pt solid windowtext; mso-border-right-alt: 0.5000pt solid windowtext; border-top: none; mso-border-top-alt: 0.5000pt solid windowtext; border-bottom: 1.0000pt solid windowtext; mso-border-bottom-alt: 0.5000pt solid windowtext;"
                    valign="center" width="332">
                    <p style="text-align: center; line-height: 20.0000pt; mso-line-height-rule: exactly;"
                        align="center"><span
                            style="font-family: 方正仿宋_GBK; mso-hansi-font-family: 仿宋; mso-bidi-font-family: 'Times New Roman'; font-size: 14.0000pt; mso-font-kerning: 1.0000pt;"> </span>
                    </p>                    
                </td>
            </tr>`

                    this.allprojectinfo.push(projecttable);

                } catch (error) {
                    console.error('Error fetching project details:', error);
                }
            }
        },
        // 发布认证前公示
        submitForm() {

            this.fetchProjectDetails(this.adviceForm.projectid);
            let startdate = this.adviceForm.date[0];
            let enddate = this.adviceForm.date[1];
            let publisdate = this.adviceForm.publishdate;
            let standardtypename=this.standardtypename;

            let projectinfo = ''
            if (this.allprojectinfo.length > 1) {
                projectinfo = this.allprojectinfo.join(' ');
            } else if (this.allprojectinfo.length == 0) {
                this.$message.warning('未选择标准', '提示')
            } else {
                projectinfo = this.allprojectinfo[0];
            }
            let content = `<div>
    <p style="text-indent: 32pt; line-height: 30pt; text-align: justify;"><span
        style="mso-spacerun: 'yes'; font-family: 方正仿宋_GBK; mso-hansi-font-family: Calibri; mso-bidi-font-family: 'Times New Roman'; font-size: 16.0000pt; mso-font-kerning: 1.0000pt;">为进一步提高江苏省工程建设企业技术标准质量，广泛听取社会各界意见，根据《江苏省工程建设企业技术标准认证公告规则》规定，现将通过专家初审的工程建设企业技术标准和标准设计进行认证前公示，公示时间为${startdate}至${enddate}。</span>
    </p>
    <p style="text-indent: 32pt; line-height: 30pt; text-align: justify;"><span
            style="mso-spacerun: 'yes'; font-family: 方正仿宋_GBK; mso-hansi-font-family: Calibri; mso-bidi-font-family: 'Times New Roman'; font-size: 16.0000pt; mso-font-kerning: 1.0000pt;">公示期间，任何单位及个人如对公示对象有异议，请在公示期内以书面形式向省工程建设标准站反映，并提供必要的佐证材料，以便核实查证。以单位名义反映的，须加盖公章；以个人名义反映的，须签署真实姓名并提供身份信息，留下联系电话、工作单位、地址等有效联系方式。凡匿名、冒名或超出期限的异议不予受理。</span>
    </p>
    <p style="text-indent: 32pt; line-height: 30pt; text-align: justify;"><span
            style="mso-spacerun: 'yes'; font-family: 方正仿宋_GBK; mso-hansi-font-family: Calibri; mso-bidi-font-family: 'Times New Roman'; font-size: 16.0000pt; mso-font-kerning: 1.0000pt;"><span
                style="font-family: 方正仿宋_GBK;">联系地址：${this.adviceForm.address}</span></span></p>
    <p style="text-indent: 32pt; line-height: 30pt; text-align: justify;"><span
            style="mso-spacerun: 'yes'; font-family: 方正仿宋_GBK; mso-hansi-font-family: Calibri; mso-bidi-font-family: 'Times New Roman'; font-size: 16.0000pt; mso-font-kerning: 1.0000pt;"><span
                style="font-family: 方正仿宋_GBK;">联系电话：${this.adviceForm.phone}</span></span></p>
</div>
<p>&nbsp;
</p>
<p style="mso-para-margin-top: 0.5000gd; text-align: center;" align="center"><span
        style="mso-spacerun: 'yes'; font-family: 方正黑体_GBK; mso-hansi-font-family: Calibri; mso-bidi-font-family: 'Times New Roman'; font-size: 16.0000pt; mso-font-kerning: 1.0000pt;">通过专家初审的工程建设企业技术标准一览表</span>
</p>
<div align="center">
    <table style="width: 723.85pt; border: none;" border="1" cellspacing="0">
        <tbody>
            <tr style="height: 56.1000pt; page-break-inside: avoid;">
                <td style="width: 49.6500pt; padding: 0.0000pt 5.4000pt 0.0000pt 5.4000pt; mso-border-left-alt: 0.5000pt solid windowtext; mso-border-right-alt: 0.5000pt solid windowtext; mso-border-top-alt: 0.5000pt solid windowtext; mso-border-bottom-alt: 0.5000pt solid windowtext; border: 1.0000pt solid windowtext;"
                    valign="center" width="66">
                    <p style="text-indent: 7.0500pt; mso-char-indent-count: 0.5000; text-align: center; line-height: 15.0000pt; mso-line-height-rule: exactly;"
                        align="center"><strong><span style="font-family: 仿宋_GB2312; font-size: 14pt;">序号</span></strong>
                    </p>
                </td>
                <td style="width: 225.6000pt; padding: 0.0000pt 5.4000pt 0.0000pt 5.4000pt; mso-border-left-alt: 0.5000pt solid windowtext; mso-border-right-alt: 0.5000pt solid windowtext; mso-border-top-alt: 0.5000pt solid windowtext; mso-border-bottom-alt: 0.5000pt solid windowtext; border: 1.0000pt solid windowtext;"
                    valign="center" width="300">
                    <p style="text-align: center;" align="center"><strong><span
                                style="font-family: 仿宋_GB2312; mso-hansi-font-family: 黑体; mso-bidi-font-family: 'Times New Roman'; mso-ansi-font-weight: bold; font-size: 14.0000pt; mso-font-kerning: 1.0000pt;">标准名称</span></strong>
                    </p>
                </td>
                <td style="width: 199.1000pt; padding: 0.0000pt 5.4000pt 0.0000pt 5.4000pt; mso-border-left-alt: 0.5000pt solid windowtext; mso-border-right-alt: 0.5000pt solid windowtext; mso-border-top-alt: 0.5000pt solid windowtext; mso-border-bottom-alt: 0.5000pt solid windowtext; border: 1.0000pt solid windowtext;"
                    valign="center" width="265">
                    <p style="text-align: center;" align="center"><strong><span
                                style="font-family: 仿宋_GB2312; mso-hansi-font-family: 黑体; mso-bidi-font-family: 'Times New Roman'; mso-ansi-font-weight: bold; font-size: 14.0000pt; mso-font-kerning: 1.0000pt;">企业名称</span></strong>
                    </p>
                </td>
                <td style="width: 249.5000pt; padding: 0.0000pt 5.4000pt 0.0000pt 5.4000pt; mso-border-left-alt: 0.5000pt solid windowtext; mso-border-right-alt: 0.5000pt solid windowtext; mso-border-top-alt: 0.5000pt solid windowtext; mso-border-bottom-alt: 0.5000pt solid windowtext; border: 1.0000pt solid windowtext;"
                    valign="center" width="332">
                    <p style="text-align: center;" align="center"><strong><span
                                style="font-family: 仿宋_GB2312; mso-hansi-font-family: 黑体; mso-bidi-font-family: 'Times New Roman'; mso-ansi-font-weight: bold; font-size: 14.0000pt; mso-font-kerning: 1.0000pt;">应用工程</span></strong>
                    </p>
                </td>
            </tr>
            ${projectinfo}
        </tbody>
    </table>
</div>
<p style="line-height: 30pt;">&nbsp;</p>
<p style="line-height: 30pt;">&nbsp;</p>
<p style="line-height: 30pt; text-align: right;"><span style="font-family: 方正仿宋_GBK; font-size: 16pt;">&nbsp; &nbsp;
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;<span
            style="font-family: 方正仿宋_GBK;">江苏省工程建设标准站</span></span></p>
<p style="line-height: 30pt; text-align: right;"><span
        style="font-family: 方正仿宋_GBK; font-size: 16pt;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
            style="font-family: 方正仿宋_GBK;">${publisdate} </span><span style="font-family: 方正黑体_GBK; font-size: 16pt;">
        &nbsp;&nbsp;</span><span style="font-family: 方正仿宋_GBK; font-size: 16pt;">&nbsp;</span></p>`

            let params = {
                subject: `江苏省工程建设企业技术${standardtypename}认证前公示(${publisdate})`,
                content: content,
                category_id: 16,
                users: this.$store.state.id,
                token: this.token
            }

            standardModelAdd(params).then(rec => {
                this.$message.info('新建标准公示成功！', '提示');
                this.adviceDialogVisible = false;
            })

        },

        getArtFile(obj) {
            this.fileObj = obj
        }
    }

}

</script>
<style scoped>
/deep/ .stand_dialog {
    width: 1080px !important;
}

/deep/ .adviceForm .el-form-item__label {
    width: 150px;
}

/deep/ .adviceForm .el-select,
/deep/ .adviceForm .el-input {
    width: 600px;
}
</style>
<style lang="scss" scoped>
.addvice_content {
    .title {
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid #333;
        margin-bottom: 11px;

        .text {
            font-weight: bold;
            font-size: 24px;
            text-align: center;
            padding: 0px 11px 16px 11px;
        }
    }

    .article {
        width: 100%;
        display: flex;
        font-size: 14px;
        text-align: left;
        padding-bottom: 11px;
        padding-top: 4px;

        // line-height: 36px;
        .text {
            padding: 10px 11px 16px 0;
        }

        .stand_name {
            width: 200px;
        }

        span {
            padding-top: 8px;
        }
    }

    .show_text {
        display: flex;
        font-size: 14px;
        text-align: left;
        padding-bottom: 11px;

        span {
            padding-top: 8px;
        }
    }
}
</style>